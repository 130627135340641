.user-icon {
    margin-right: 10px;
}

.userInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Avenir;
    font-size: medium;
}
