.App {
  text-align: center;
  font-family: Avenir;
}

h1 {
  text-align: center;
}

.demo {
  font-family: Avenir;
  font-weight: bold;
  text-align: center;
}