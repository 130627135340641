.quickStart {
    font-family: Avenir;
    text-align: center;
    display: inline-block;
    /* margin-left: 10%; */
    padding-top: 1%;
    font-size: medium;
}

.tableauHelp, .projectHelp, .menuHelp, .uploadHelp, .contactHelp, .homeHelp, .userHelp {
    display: flex;
    padding: 5px;
    text-align: left;
    margin-left: 5%;
}

.menuP {
    padding-left: 25px;
}

.menu {
    padding-right: 10px;
}

.quick {
    font-family: Avenir;
    font-size: 22px;
    padding-left: 15px;
}

.qs-title {
    font-family: Avenir;
    font-size: xx-large;
}

.tableau_p {
    display: flex;
    padding: 5px;
    text-align: justify;
    margin-left: 5%;
}
.quickStart {
    margin-left: 18%;
    margin-right: 18%;
}

.user-help {
    margin-left: 0%;
}

.company-icons {
    position: 'absolute';
    align-content: 'center';
    display: 'inline-block';
    margin-left: 'calc(100vw - 68%)';
    bottom: 'calc(100vh - 60%)';
}

.projectSelect {
    display: flex;
    padding: 5px;
    text-align: left;
    margin-left: 5%;
    font-size: larger;
    font-weight: bold;
}

