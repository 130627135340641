.downloadFiles {
    font-family: Avenir;
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-top: 5%;
    /* margin-left: 10%; */
}

.title {
    font-weight: bold;
    font-family: Avenir;
}

.projectDown, .gisaidDown, .projectMeta, .template {
    padding: 15px;
}

.helpful {
    font-size: 12px;
    font-family: Avenir;
}

.download-btn, .hosp_temp {
    margin-right: 10px;
}