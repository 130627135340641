.copyright {
    position: fixed;
    font-size: xx-small;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    margin-left: calc(100vw - 58%);
    bottom: 1%;
    /* display: grid; */
}

.logoImg {
    position: fixed;
    font-size: xx-small;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    bottom: 0%;
    width: 75px;
    height: 50px;
    margin-left: 220px;
}

.logoImgNsf {
    position: fixed;
    font-size: xx-small;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    bottom: 1%;
    width: 120px;
    height: 50px;
    margin-left: calc(100vw - 43%);
}

.logoImgSbir {
    position: fixed;
    font-size: xx-small;
    align-content: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding-top: 18px;
    bottom: 1%;
    width: 130px;
    height: 50px;
    margin-left: calc(100vw - 39%);
}
